import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Avatar, Container, Chip, Box, Dialog, Typography, Card, CardContent, CardActionArea, CardHeader, CardMedia, Skeleton } from '@mui/material';

const containerCSS = css`
display: flex; 
align-items: center; 
justify-content: space-evenly;
flex-wrap: wrap-reverse;
border-bottom: 1px solid;
padding-bottom: 16px;
margin-bottom: 16px;
`;
const boxCss = css`
    max-width: 720px;
`;
const spriteCss = css`
    height: 200px;
    background-size: contain;
    margin-bottom: 16px;
`;
const imageListCss = css`
    display: grid;
    gap: 16px;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
`;
const chipListCss = css`
    display: grid;
    gap: 16px;
    grid-template-columns: repeat( auto-fit, minmax(175px, 1fr) );
    margin-bottom: 16px;
`;

const pokeApi = 'https://pokeapi.co/api/v2';

const gen1Request = () => {
    let i = 1;
    const requestBlock = []
    while (i < 152) {
        requestBlock.push(`${pokeApi}/pokemon/${i}`);
        i++;
    }
    return requestBlock;
}
const gen2Request = () => {
    let i = 152;
    const requestBlock = []
    while (i < 252) {
        requestBlock.push(`${pokeApi}/pokemon/${i}`);
        i++;
    }
    return requestBlock;
}
const gen3Request = () => {
    let i = 252;
    const requestBlock = []
    while (i < 387) {
        requestBlock.push(`${pokeApi}/pokemon/${i}`);
        i++;
    }
    return requestBlock;
}
const gen4Request = () => {
    let i = 387;
    const requestBlock = []
    while (i < 494) {
        requestBlock.push(`${pokeApi}/pokemon/${i}`);
        i++;
    }
    return requestBlock;
}
const gen5Request = () => {
    let i = 494;
    const requestBlock = []
    while (i < 650) {
        requestBlock.push(`${pokeApi}/pokemon/${i}`);
        i++;
    }
    return requestBlock;
}
const gen6Request = () => {
    let i = 650;
    const requestBlock = []
    while (i < 722) {
        requestBlock.push(`${pokeApi}/pokemon/${i}`);
        i++;
    }
    return requestBlock;
}
const gen7Request = () => {
    let i = 722;
    const requestBlock = []
    while (i < 810) {
        requestBlock.push(`${pokeApi}/pokemon/${i}`);
        i++;
    }
    return requestBlock;
}
const gen8Request = () => {
    let i = 810;
    const requestBlock = []
    while (i < 899) {
        requestBlock.push(`${pokeApi}/pokemon/${i}`);
        i++;
    }
    return requestBlock;
}
const sendGetRequest = async (requests) => {
    try {
        const response = await axios.all((requests).map((request) => axios.get(request)));
        return response;
    } catch (error) {
        console.error(error);
    }
}

const PokemonCard = ({ data, loading }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    if (loading) {
        return (
            <>
                <Skeleton variant="rectangular" height={275} />
                <Skeleton variant="rectangular" height={275} />
                <Skeleton variant="rectangular" height={275} />
                <Skeleton variant="rectangular" height={275} />
                <Skeleton variant="rectangular" height={275} />
                <Skeleton variant="rectangular" height={275} />
                <Skeleton variant="rectangular" height={275} />
                <Skeleton variant="rectangular" height={275} />
            </>
        )
    }
    return (
        <>
            <Card>
                <CardActionArea onClick={() => setDialogOpen(true)}>
                    <CardHeader avatar={<Avatar>{data.id.toString().padStart(3, '0')}</Avatar>} title={data.name} />
                    <CardMedia css={spriteCss} image={data.sprites.other['official-artwork'].front_default} alt={`${data.name} sprite`} />
                </CardActionArea>
            </Card>
            <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
                <Card>
                    <CardHeader avatar={<Avatar>{data.id.toString().padStart(3, '0')}</Avatar>} title={data.name} />
                    <CardMedia css={spriteCss} image={data.sprites.other['official-artwork'].front_default} alt={`${data.name} sprite`} />
                    <CardContent></CardContent>
                </Card>
            </Dialog>
        </>
    )
}

const IndexPage = () => {
    const [pokemon, setPokemon] = useState(gen1Request());
    const [output, setOutput] = useState(<PokemonCard loading />);
    useEffect(() => {
        setOutput(<PokemonCard loading/>)
        sendGetRequest(pokemon).then((data) => setOutput(data.map((result) => {
        return <PokemonCard data={result.data} />
        })))
    }, [pokemon])
    return (
        <>
            <Container disableGutters css={containerCSS}>
                <Box css={boxCss}>
                    <Typography gutterBottom component="h1" variant="h3" align="center">
                        PokeDex
                    </Typography>
                    <Typography paragraph component="p" variant="h6">
                        Example integration with PokeApi
                    </Typography>
                </Box>
                <a target="_blank" rel="noreferrer" href="https://pokeapi.co"><StaticImage quality={100} src="https://raw.githubusercontent.com/PokeAPI/media/master/logo/pokeapi_256.png" alt="PokeAPI" /></a>
            </Container>
            <Container disableGutters css={chipListCss}>
                <Chip label="Generation 1 (Kanto)" onClick={() => setPokemon(gen1Request())} />
                <Chip label="Generation 2 (Johto)" onClick={() => setPokemon(gen2Request())} />
                <Chip label="Generation 3 (Hoenn)" onClick={() => setPokemon(gen3Request())} />
                <Chip label="Generation 4 (Sinnoh)" onClick={() => setPokemon(gen4Request())} />
                <Chip label="Generation 5 (Unova)" onClick={() => setPokemon(gen5Request())} />
                <Chip label="Generation 6 (Kalos)" onClick={() => setPokemon(gen6Request())} />
                <Chip label="Generation 7 (Alola)" onClick={() => setPokemon(gen7Request())} />
                <Chip label="Generation 8 (Galar)" onClick={() => setPokemon(gen8Request())} />
            </Container>
            <Container disableGutters css={imageListCss}>
                {output}
            </Container>
        </>
    )
}

export default IndexPage;